input {
	outline: none !important;
}

textarea {
	outline: none !important;
	display: inline-block;
	min-height: 10px;
	width: 300px;
}
